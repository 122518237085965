import { ChristmasRandom } from '../src/views/Christmas-random';

function App() {
  return (
    <div className="App">
      <ChristmasRandom />
    </div>
  );
}

export default App;
