import { Button } from '@mui/material';
import React, { useState } from 'react';
import $ from 'jquery';
import "../../assets/css/style.css";
import sound from "../../assets/audio/Nhac-nen-ai-la-trieu-phu-v1.mp3"

export const ChristmasRandom = () => {
  const [num,setNum]= useState(0)
  
  function randomNumberInRange(min, max) {
    //  get number between min and max
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  function doSetTimeout() {
      setTimeout(function() {
        $('#loading').fadeOut();
      }, 1000);
      setTimeout(function() {
          $('#loading').fadeIn();
      }, 1000); 
  }
  function actionRandom(){
    for (var i = 1; i <= 19; ++i){
      doSetTimeout()
    }
    setTimeout(function() {
      $('#audio')[0].play();
    }, 1000);
    setTimeout(function() {
      $('#loading').css('display','none');
      $('#lucky').fadeIn();
    }, 22000); 
  }
  const handleClick = () => {
    $('#loading').css('display','none');
    $('#lucky').css('display','none');
    actionRandom();
    setNum(randomNumberInRange(1, 400));
  };
  return (
    <div className='body'>
      <div id="box-container" className='text-center'>
        <h2>Món quà sẽ thuộc về người có mã dự thưởng là:</h2>
        <div id="loading" className='fs-5r' style={{display:'none'}}><h1>Đang tìm ...</h1></div>
        <div id="lucky" className='fs-7r' style={{display:'none'}}>
            <h1>Số: {num}</h1>
        </div>
        <Button variant="contained" onClick={handleClick} style={{position:'absolute',bottom:'0', left:'50%',transform: 'translateX(-50%)'}}>Tìm mã dự thưởng</Button>
      </div>
      <audio id="audio" src={sound}></audio>
    </div>
  )
}
